<template>
  <div class="self-center">
    <div class="d-flex flex-row flex-wrap">
      <!-- View -->
      <b-button
        v-b-tooltip.hover.top="'View Role'"
        class="btn-icon grid-btn"
        variant="outline-dark"
        @click="viewRecord"
      >
        <feather-icon
          icon="EyeIcon"
          svg-classes="hover:text-primary stroke-current"
        />
      </b-button>

      <!-- Edit -->
      <b-button
        v-b-tooltip.hover.top="'Edit Role'"
        class="btn-icon grid-btn"
        variant="outline-dark"
        @click="editRecord"
      >
        <feather-icon
          class="text-warning"
          icon="EditIcon"
          svg-classes="hover:text-primary stroke-current"
        />
      </b-button>

      <!-- Delete -->
      <b-button
        v-b-tooltip.hover.top="'Delete this role'"
        class="btn-icon grid-btn"
        variant="outline-dark"
      >
        <feather-icon
          class="text-danger"
          icon="Trash2Icon"
          svg-classes="hover:text-primary stroke-current"
          @click.prevent="delete_role"
        />
      </b-button>
    </div>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'CellRendererActions',

  components: {
    //
  },
  data() {
    return {
      isLoading: false,
    }
  },

  methods: {
    viewRecord() {
      this.$router.push({ name: 'roles-view', params: { id: this.params.data['_id'] } })
    },

    editRecord() {
      this.$router.push({ name: 'roles-edit', params: { id: this.params.data['_id'] } })
    },

    invokeParentMethod(popup, edit_data) {
      this.params.context.componentParent.methodFromParent(popup, edit_data)
    },

    // delete role
    confirmDeactivateRecord() {
      if (this.$checkRights(this.$route.meta.section, 'delete')) {
        this.$bvModal
          .msgBoxConfirm(
            `this will also delete "${this.params.data.name}" assigned on other user, Are you sure?`,
            {
              title: 'Confirm User Deletion',
              size: 'sm',
              okVariant: 'danger',
              okTitle: 'Delete',
              cancelTitle: 'Cancel',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            },
          )
          .then(value => {
            if (value) {
              this.delete_role(this.params.data['_id'])
            }
          })
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Inadequate permission',
              icon: 'CheckCircleIcon',
              text: 'You dont have enough rights to delete data in this section',
              variant: 'danger',
              position: 'center',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    deactivateRecord() {
      this.delete_role()
    },
    delete_role() {
      // const { token } = this.$store.state.auth.ActiveUser

      // this.$http.defaults.headers.common.Authorization = `Token ${token}`
      this.$http
        .delete(`/api/roles/${this.params.data._id}`, {})
        .then(response => {
          if (response.data.success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Account Operations',
                  icon: 'CheckCircleIcon',
                  text: 'Role deleted successfully.',
                  variant: 'success',
                  position: 'center',
                },
              },
              { timeout: this.$longestTimeout },
            )

            this.get_roles()
          }

        })
        .catch(error => {
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Operation Failed',
                icon: 'CheckCircleIcon',
                text: 'Could not delete role, please try again later',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )
        })
    },
  },
}
</script>
