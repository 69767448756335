<template>
  <div class="self-center">
    <b-badge
      v-if="params.account_approved"
      pill
      class="h-6"
      variant="success"
    >
      Active
    </b-badge>
    <b-badge
      v-else
      pill
      class="h-6"
      variant="danger"
    >
      Deactivated
    </b-badge>
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'

export default {
  name: 'CellRendererStatus',
  components: {
    BBadge,
  },
  props: {
    params: Object,
  },
}
</script>

<style lang="scss" scpoped>

</style>
