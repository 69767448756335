<template>
  <div>
    <b-row class="mt-1 mx-1">
      <b-button
        class="my-2"
        variant="primary"
        @click="$router.push({ name: 'roles-create' })"
      >
        Add User Role
      </b-button>
    </b-row>

    <b-row class="mt-1 mx-1 w-100">
      <b-card
        no-body
        class="w-100 mr-2"
      >
        <b-card-body>
          <b-card-title>Roles</b-card-title>
          <b-card-sub-title>Contains roles you have previously created</b-card-sub-title>
        </b-card-body>

        <!-- AgGrid Table -->
        <div style="min-width: 30vw;min-height: 20vw; width: 100%; height: 100%;">
          <!-- <ag-grid-vue
            ref="agGridTable"
            :context="context"
            :components="components"
            :grid-options="gridOptions"
            style="width: 100%; height:55vh;"
            class="ag-theme-material"
            :column-defs="columnDefs"
            :default-col-def="defaultColDef"
            :row-data="rolesData"
            row-selection="multiple"
            col-resize-default="shift"
            :animate-rows="true"
            :pagination="true"
            :pagination-page-size="paginationPageSize"
            :suppress-pagination-panel="true"
          /> -->
          <b-pagination
            v-model="currentPage"
            class="my-1 float-right mr-2"
            :total-rows="rolesData.length"
            :per-page="paginationPageSize"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card>
    </b-row>
  </div>
</template>

<script>

import PermissionsCard from '@/views/user-management-system/components/PermissionsCard.vue'

import CellRendererActions from './CellRendererActions.vue'

export default {
  components: {

    PermissionsCard,

    CellRendererActions,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rolesTableFields: [
        {
          key: 'name',
          label: 'Role Name',
          sortable: true,
        },
        {
          key: 'description',
        },
        {
          key: 'actions',
        },
      ],
      rolesData: [],

      // AgGrid
      context: null,
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        // sortable: true,
        resizable: true,
        suppressMenu: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: 'Name',
          field: 'name',
          filter: true,
          width: 200,
        },
        {
          headerName: 'Description',
          field: 'description',
          width: 370,
        },
        {
          headerName: 'Actions',
          field: 'edit_delete',
          width: 170,
          type: 'rightAligned',
          cellRendererFramework: 'CellRendererActions',
        },
      ],

      // Cell Renderer Components
      components: {
        CellRendererActions,
      },
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      return 10
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      return 0
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        return 1
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1)
      },
    },
  },
  watch: {
    roleFilter(obj) {
      this.setColumnFilter('role', obj.value)
    },
    statusFilter(obj) {
      this.setColumnFilter('status', obj.value)
    },
    isVerifiedFilter(obj) {
      const val = obj.value === 'all' ? 'all' : obj.value === 'yes' ? 'true' : 'false'
      this.setColumnFilter('is_verified', val)
    },
    departmentFilter(obj) {
      this.setColumnFilter('department', obj.value)
    },
  },
  created() {
    this.get_roles()
  },
  methods: {
    get_roles() {
      this.$http
        .get(`/api/roles`)
        .then(response => {
          this.rolesData = response.data.roles
        })
        .catch(() => {
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Operation Failed',
                icon: 'CheckCircleIcon',
                text: 'Could not retrieve the Role list',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )
        })
    },
    // delete_role(id) {
    //   const { token } = this.$store.state.auth.ActiveUser

    //   this.$http.defaults.headers.common.Authorization = `Token ${token}`
    //   this.$http
    //     .delete(`/api/roles/${id}`, {})
    //     .then(response => {
    //       if (response.data.success) {
    //         this.$toast(
    //           {
    //             component: this.$toastContent,
    //             props: {
    //               title: 'Account Operations',
    //               icon: 'CheckCircleIcon',
    //               text: 'Role deleted successfully.',
    //               variant: 'success',
    //               position: 'center',
    //             },
    //           },
    //           { timeout: this.$longestTimeout },
    //         )

    //         this.get_roles()
    //       }
    //     })
    //     .catch(error => {
    //       console.log(error.response)
    //       this.$toast(
    //         {
    //           component: this.$toastContent,
    //           props: {
    //             title: 'Operation Failed',
    //             icon: 'CheckCircleIcon',
    //             text: 'Could not delete role, please try again later',
    //             variant: 'danger',
    //           },
    //         },
    //         { timeout: this.$longestTimeout },
    //       )
    //     })
    // },
  },
}
</script>

<style>

</style>
